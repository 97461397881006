@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  overflow-x: hidden;
  @apply bg-white;
}
.animate-bouncing {
  animation: bouncing 1.5s infinite;
}
.delays-300 {
  animation-delay: 250ms;
}
.delays-500 {
  animation-delay: 500ms;
}

@keyframes bouncing {
  0%,
  100% {
    transform: translateY(-75%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.facecontainer {
  width: 100%;
}
.face2 {
  position: relative;
  width: 100px;
  height: 100px;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777777;
  /* left: 37.5z%; */
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}
.face2:hover .sad {
  transform: rotate(-135deg);
}

.shadow {
  position: absolute;
  width: 100px;
  height: 5px;
  opacity: 0.5;
  background: #777777;
  left: 40%;
  bottom: 0;
  border-radius: 50%;
  z-index: 1;
}

.move {
  animation: move 3s ease-in-out infinite;
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.swiper {
  width: calc(10 / 12 * 100%) !important;
  height: 100%;
  /* padding-inline: 50px !important; */
}
.newSwiper {
  width: 100% !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next {
  background-image: url(../public/rightNavArrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  background-image: url(../public/leftNavArrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.no-break{
    white-space: break-spaces;
}
.bg-winnerStats{
background: linear-gradient(0deg, rgba(43, 29, 52, 0.9), rgba(43, 29, 52, 0.9)), url(../public/winnersStats.png);
background-size: cover;
background-repeat: no-repeat;
}
.bg-whatPeopleSay{
  background: linear-gradient(0deg, rgba(31, 2, 49, 0.92), rgba(31, 2, 49, 0.92)), url(../public/whatPeopleSay.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.imageContainer::-webkit-scrollbar, .imageContainer::-webkit-scrollbar-thumb, .imageContainer::-webkit-scrollbar-track, .imageContainer::-webkit-scrollbar-track {
  height: 0;
  width: 0;
  display: none;
}
.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .embla {
    --slide-size: 37.5%;
  }
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide2 {
  --slide-size: 30%;
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.image-container{
display: grid;
grid-template-rows: 20% auto;
grid-template-columns: auto auto;
column-gap: 25px;
}
/* @media screen and (min-width: 768px) {  */
  
  .image-3{
    top: 24%;
  }
  .image-5{
    top: -30%;
  }
.image-4{
  top: -11%;
}
/* } */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
li{
  margin-block: 10px;
}